import { Component, HostBinding, Input } from '@angular/core';
import { AgWidgetConfig, isNotNullOrUndefined, keysOf } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { combineLatest, map, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { HeaderLogo, isAskAgoraComponent, MenuItem } from '../models';
import { MainNavService } from '../services/main-nav.service';

interface ComponentState {
  clientId: string;
  menuItems: MenuItem[];
  activeItem: MenuItem | null;
  headerLogo: HeaderLogo | null;
  isNavExpanded: boolean;
  renderWidget: boolean;
  activePage: MenuItem | null;
}

interface Projections {
  showWidget: boolean;
}

type PublicState = Omit<ComponentState, 'renderWidget'>;

type ViewModel = PublicState & Projections;

const initialPublicState: PublicState = {
  clientId: '',
  menuItems: [],
  activeItem: null,
  activePage: null,
  headerLogo: null,
  isNavExpanded: false
};

const initialState: ComponentState = {
  ...initialPublicState,
  renderWidget: true
};

@Component({
  selector: 'mri-shared-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.scss'],
  providers: [RxState]
})
export class MainNavBarComponent {
  public widgetOpen = false;
  @HostBinding('class.mri-menu-bar') hostClass = true;

  @HostBinding('class.expanded') get isExpanded() {
    return this.state.get('isNavExpanded');
  }

  @Input() set headerLogo(headerLogo: HeaderLogo | null) {
    this.state.set({ headerLogo });
  }

  @Input() set renderWidget(renderWidget: boolean) {
    this.state.set({ renderWidget });
  }

  @Input() set menuItems(menuItems: MenuItem[]) {
    this.mainNavService.menuItems = menuItems;
  }

  @Input() detailedErrorMessagesWidget = false;

  @Input()
  vm$: Observable<ViewModel>;

  apiHost = this.agWidgetConfig.baseApiUrl;

  constructor(
    private mainNavService: MainNavService,
    private state: RxState<ComponentState>,
    private agWidgetConfig: AgWidgetConfig,
    private authService: AuthService
  ) {
    // Set initial state
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...
    this.state.connect('menuItems', this.mainNavService.menuItems$);
    this.state.connect('activeItem', this.mainNavService.activeItem$);
    this.state.connect('activePage', this.mainNavService.activePage$);
    this.state.connect('clientId', this.createClientId$());

    // Create projections (calculations from ComponentState)...
    const showWidget$ = this.state
      .select(selectSlice(['renderWidget', 'clientId']))
      .pipe(map(({ renderWidget, clientId }) => renderWidget && !!clientId));

    // Create ViewModel (Projections + PublicState)...
    const publicState$ = this.state.select(selectSlice(keysOf(initialPublicState)));
    this.vm$ = combineLatest([publicState$, showWidget$]).pipe(
      map(([publicState, showWidget]) => ({ ...publicState, showWidget }))
    );

    // side effects if any...
    // none
  }

  itemHref(menuItem: MenuItem) {
    return menuItem.customIcon
      ? `assets/icons/menu-icons.svg#${menuItem.customIcon}-icon`
      : `assets/@mri/svg-icons/mri-icon-sprite.svg#${menuItem.iconName}`;
  }

  clickIcon(activeItem: MenuItem) {
    this.mainNavService.activate(activeItem);
  }

  toggleNavExpansion() {
    const currentState = this.state.get();
    const toggledValue = !currentState.isNavExpanded;
    this.state.set({ isNavExpanded: toggledValue });
  }

  logout() {
    this.authService.logOut();
  }

  widgetClicked() {
    this.widgetOpen = !this.widgetOpen;
    this.state.set({ isNavExpanded: false });
  }

  onAskAgoraClosed(activeItem: MenuItem | null) {
    if (activeItem && isAskAgoraComponent(activeItem)) {
      this.mainNavService.activate(activeItem);
    }
  }

  private createClientId$() {
    const authServiceSigninClientId$ = this.authService.userLoaded$.pipe(
      map(user => user.clientId),
      startWith(null)
    );
    // favour the client ID set in the main nav service, using the client ID from the auth service as fallback
    return combineLatest([authServiceSigninClientId$, this.mainNavService.getClientId()]).pipe(
      map(([clientId, clientIdFromNavService]) => (clientIdFromNavService ? clientIdFromNavService : clientId)),
      isNotNullOrUndefined()
    );
  }
}
