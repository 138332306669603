import { APP_INITIALIZER, FactoryProvider, inject, Injector, runInInjectionContext } from '@angular/core';
import { Settings } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { IPendoSettings, NGX_PENDO_SETTINGS_TOKEN, NgxPendoService } from 'ngx-pendo';
import { firstValueFrom } from 'rxjs';

export const PendoSettingsProvider: FactoryProvider = {
  provide: NGX_PENDO_SETTINGS_TOKEN,
  useFactory: (settings: Settings): IPendoSettings => ({
    pendoApiKey: settings.pendo.pendoApiKey
  }),
  deps: [Settings]
};

const initPendo = (authService: AuthService, injector: Injector, settings: Settings) => async () => {
  // note: route guards will NOT wait on this promise being resolved
  const user = await firstValueFrom(authService.userLoaded$);

  runInInjectionContext(injector, () => {
    const pendoSvc = inject(NgxPendoService);
    pendoSvc.initialize({
      visitor: {
        id: user.sub,
        version: settings.clientVersion
      },
      account: {
        id: user.clientId ?? '',
        version: settings.clientVersion
      }
    });
  });
};

export const PendoInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initPendo,
  deps: [AuthService, Injector, Settings],
  multi: true
};

export const pendoProviders = [PendoSettingsProvider, PendoInitializerProvider];
