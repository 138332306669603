import { CrudAction, CrudAllAction } from '@mri-platform/resource-authz';
import { RouteInfo } from '@mri-platform/shared/core';
import { EntityName } from './entity-name';

export const AppRoutes = {
  Error: RouteInfo.path('error'),
  Home: RouteInfo.path('home'),
  About: RouteInfo.path('about'),
  Dashboards: RouteInfo.path('dashboards').withEntityAuthz([EntityName.ReportInfo]).children({
    Detail: RouteInfo.detailPage()
  }),
  CreateDashboard: RouteInfo.path('create').withEntityAuthz([EntityName.ReportInfo], CrudAction.Create),
  DashboardManagement: RouteInfo.path('dashboard-management')
    .withEntityAuthz([EntityName.ReportAccess], CrudAllAction.Read)
    .children({
      Groups: RouteInfo.path('groups')
        .withEntityAuthz([EntityName.Organisation, EntityName.Group])
        .children({
          GroupDetail: RouteInfo.detailPage('group'),
          OrganisationDetail: RouteInfo.detailPage('organisation')
        }),
      Users: RouteInfo.path('users')
        .withEntityAuthz([EntityName.User], CrudAllAction.Read)
        .children({
          UserDetail: RouteInfo.detailPage('user')
        })
    }),
  UserSettings: RouteInfo.path('user-settings').withEntityAuthz([EntityName.User]).children({
    Detail: RouteInfo.detailPage()
  }),
  AddUser: RouteInfo.path('Add-user').withEntityAuthz([EntityName.User], CrudAction.Create),
  Unauthorized: RouteInfo.path('unauthorized'),
  SwitchClient: RouteInfo.path('switch-client').withEntityAuthz([EntityName.Organisation], 'Impersonate'),
  Reconciliation: RouteInfo.path('reconciliation')
    .withFeatureName('reconciliation')
    .withEntityAuthz([EntityName.PbiReport]),
  ClientOnboardConfig: RouteInfo.path('onboard-config')
    .withEntityAuthz([EntityName.Organisation, EntityName.OrganisationWorkspace])
    .children({
      // todo: enhance route-info.ts to support the comment out code below
      // for now we're NOT guarding the ConfirmDelete route with authz permissions. that's not great but NOT a security
      // issue right now as the page doesn't actually perform any save to the api.
      ConfirmDelete: RouteInfo.path('confirm-delete')
      // ConfirmDelete: RouteInfo.path('confirm-delete').withAuthz(OrganisationPrincipal.authorization[CrudAction.Delete])
    })
};
