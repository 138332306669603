import { BaseEntity } from '../base-entity';
import { OrganisationWorkspaceInfo } from './organisation-workspace-info';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction } from '@mri-platform/resource-authz';
import { EntityProperty, MetadataStore } from 'breeze-client';
import * as JsonSerializer from './json-serializer';
import { LatestProblem } from './latest-problem';
import { PbiReport } from './pbi-report';
import { Report } from './report';
/// </code-import>

export interface ReportInfo {
  id: string;
  datasetId: string;
  description?: string;
  embedUrl: string;
  hiddenById?: string;
  hidesReportId?: string;
  isCustom: boolean;
  isDefault: boolean;
  isFavorite: boolean;
  isPublished: boolean;
  lastViewed?: Date;
  latestProblem?: string;
  name: string;
  organisationWorkspaceId: string;
  ownerId: string;
  parentReportId?: string;
  pbiReportId: string;
  pbiWorkspaceId: string;
  templateDatasetName?: string;
  templateReportName?: string;
  version: number;
  webUrl: string;
  organisationWorkspace: OrganisationWorkspaceInfo;
}

/// <module-code> Place module level code between <module-code> tags
export interface ReportInfo {
  isBookmarkLoaded: boolean;
}
type ReportEntityType = ReportInfo | Report;
export const reportSortByName = <T extends ReportEntityType>(a: T, b: T): number =>
  (a.name ?? '').localeCompare(b.name ?? '') || (a.description ?? '').localeCompare(b.description ?? '');

export const excludeHiddenReports = <T extends ReportEntityType>(reports: T[]) => {
  // note: `!all.find(other => other.hidesReportId === r.id)` is a workaround... we should be able to use `!r.hiddenById`
  // however the server isn't being kind to us to return the updated state of the report being hidden when publishing a report
  return reports.filter((r, _, all) => !all.find(other => other.hidesReportId === r.id));
};

export const reportSortByLastViewed = (a: ReportInfo, b: ReportInfo): number =>
  (b.lastViewed ?? new Date('1900-01-01')).getTime() - (a.lastViewed ?? new Date('1900-01-01')).getTime();

//default basic sorting to ensure default is ordered first
export const reportSort = (reports: ReportInfo[]) => {
  return [[...reports].filter(x => x.isDefault), [...reports].filter(x => !x.isDefault).sort(reportSortByName)].flat();
};
/// </module-code>

export class ReportInfo extends BaseEntity {
  /// <code> Place custom code between <code> tags
  constructor() {
    super();
    this.lastViewed = new Date();
  }

  static fieldDelimiter = '~';

  static authorization: AuthzContextsActionMap = {
    ...AuthzContextsActionMap.crudFor(EntityName.ReportInfo),
    [CrudAction.Read]: [
      AuthzContext.readFor(EntityName.ReportInfo),
      { r: EntityName.ReportActivityRecord, a: [CrudAction.Create, CrudAction.Update] }
    ],
    [CrudAction.Update]: AuthzContext.updateFor(
      BaseEntity.qualifiedPropName<ReportInfo>(EntityName.ReportInfo, 'version')
    )
  };

  private _problem?: LatestProblem | null;
  get problem(): LatestProblem | undefined {
    if (this._problem !== undefined) {
      //if its null we're returning undefined
      return this._problem ?? undefined;
    }

    if (this.latestProblem?.includes(ReportInfo.fieldDelimiter)) {
      const problemValues = this.latestProblem.split(ReportInfo.fieldDelimiter);
      this._problem = {
        //problemValues will be alphabetically sorted by key as per code beow in serializerFn
        isRecoverable: problemValues[0].toLowerCase() === 'true',
        message: problemValues[1],
        source: problemValues[2]
      };
    } else {
      this._problem = this.latestProblem ? JsonSerializer.deserializeJson(this.latestProblem) : null;
    }
    return this._problem ?? undefined;
  }
  set problem(value: LatestProblem | undefined) {
    this._problem = undefined;
    if (value != undefined) {
      throw 'Updates to a stringified JSON backing field is not implemented as cloudflare will reject this';
    }
    this.latestProblem = undefined;
    // server doesn't (currently) care what our original value is, just that there is one to let breeze know that
    // this property has changed
    Object.assign(this.entityAspect.originalValues, {
      [ReportInfo.propName('latestProblem')]: 'json_replaced_with_cloudflare_safe_value'
    });
  }

  static register(metadataStore: MetadataStore) {
    metadataStore.setEntityTypeForResourceName('ReportInfos', EntityName.ReportInfo);

    const et = metadataStore.getAsEntityType('ReportInfo', false);
    et.serializerFn = (prop: EntityProperty, val: unknown) => {
      if (prop.name !== ReportInfo.propName('latestProblem') || !val) return val;
      if (typeof val !== 'string' || val.includes(ReportInfo.fieldDelimiter)) return val;

      // make cloudflare happy by NOT serializing to json, but instead to a tilda-delimited string
      // note: the server currently doesn't use the string
      const problem = JsonSerializer.deserializeJson<LatestProblem>(val);
      return Object.entries(problem)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([, value]) => value)
        .join(ReportInfo.fieldDelimiter);
    };
  }

  static propName(name: keyof ReportInfo) {
    return name;
  }

  publishDraft(draft: PbiReport) {
    if (!draft.isDraft) throw Error('instance is not a draft');

    draft.name = this.name;
    draft.isDraft = false;
    this.version += 1;
  }

  getDatasetName() {
    if (!this.templateDatasetName) return '';

    const tagRegex = /\[[^\]]+\]/g;
    return this.templateDatasetName.replace(tagRegex, '');
  }

  get workspaceLabel() {
    return this.entityAspect.isNavigationPropertyLoaded(ReportInfo.propName('organisationWorkspace'))
      ? this.organisationWorkspace.workspaceLabel
      : '<<not loaded>>';
  }

  /// </code>
  // Generated code. Do not place code below this line.
}
